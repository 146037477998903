import React, { useEffect, useState } from "react"
import moment from "moment"
import toast from "react-hot-toast"
import { DebounceInput } from "react-debounce-input"

import API from "../../../api/events"

import Loader from "../Loader"

const KeyCoverage = (props) => {
    
    // const {} = props

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])

    const [yearsList, setYearsList] = useState([])
    let allMonths = [
        {id: 1, label: 'Jan'},
        {id: 2, label: 'Feb'},
        {id: 3, label: 'March'},
        {id: 4, label: 'April'},
        {id: 5, label: 'May'},
        {id: 6, label: 'June'},
        {id: 7, label: 'July'},
        {id: 8, label: 'Aug'},
        {id: 9, label: 'Sep'},
        {id: 10, label: 'Oct'},
        {id: 11, label: 'Nov'},
        {id: 12, label: 'Dec'},
    ]
    const [monthsList, setMonthsList] = useState(allMonths)
    const [currentYear, setCurrentYear] = useState(null)
    const [currentMonth, setCurrentMonth] = useState(null)
    const [selectedYear, setSelectedYear] = useState(null)
    const [selectedMonth, setSelectedMonth] = useState(null)
    const [searchText, setSearchText] = useState('')
    
    const resetData = () => {
        setSearchText('')
        setSelectedYear(null)
        setSelectedMonth(null)
        setMonthsList(allMonths)
        getCoverageData()
    }

    const onSearchTextChange = (e) => {
        const { value } = e?.target
        let query = `?keyword=${value}`
        setSearchText(value)
        let formattedMonth = Number(selectedMonth) >= 10 ? selectedMonth : '0'+selectedMonth
        if(selectedYear) query = query+'&year='+selectedYear
        if(selectedMonth) query = query+'&month='+formattedMonth
        getCoverageData(query)
    }

    const formatYearList = () => {
        const d = new Date();
        let year = d.getFullYear();
        let month = d.getMonth()
        setCurrentYear(year)
        setCurrentMonth(month)
        let arr = []
        for (var i = year; i >= 2000; i--) { 
            // let obj = {id: i, label: i}
            arr.push(i)
        }
        setYearsList(arr)
    }

    const handleYearChange = (e) => {
        const { value } = e?.target
        setSelectedYear(value)
        setSelectedMonth(null)
        if(Number(value) === Number(currentYear)) {
            let index = allMonths.findIndex(el => el?.id === currentMonth+1)
            setMonthsList(allMonths.slice(0, index+1))
        } else {
            setMonthsList(allMonths)
        }
    }

    const handleMonthChange = (e) => {
        const { value } = e?.target
        setSelectedMonth(value)
    }

    const getCustomData = () => {
        let formattedMonth = Number(selectedMonth) >= 10 ? selectedMonth : '0'+selectedMonth
        if(selectedYear && selectedMonth === null) {
            let query = `?year=${selectedYear}`
            if(searchText?.length) query = query+'&keyword='+searchText
            getCoverageData(query)
        } else if(selectedMonth && selectedYear === null) {
            let query = `?month=${formattedMonth}`
            if(searchText?.length) query = query+'&keyword='+searchText
            getCoverageData(query)
        } else if(selectedMonth && selectedYear) {
            let query = `?month=${formattedMonth}&&year=${selectedYear}`
            if(searchText?.length) query = query+'&keyword='+searchText
            getCoverageData(query)
        }
    }

    const getCoverageData = (query) => {
        setLoading(true)
        API.getPressReleaseKeyCoverage(query ? query : '')
            .then(response => {
                if (response && response?.data && response.data.length) {
                    let arr = response.data;
                    arr.forEach(a => {
                        a['formatted_date'] = moment(a.field_event_date).format('DD MMM yyyy')
                    })
                    setList(arr)
                } else {
                    setList([])
                }
                setLoading(false)
            })
            .catch(err => {
                let respError = err?.response?.data
                if(respError) toast.error(respError)
                setList([])
                setLoading(false)
            })
    }

    useEffect(() => {
        getCoverageData()
        formatYearList()
    },[])

    useEffect(() => {
        if (typeof window.initSelect !== 'undefined') window.initSelect();
    }, [monthsList, yearsList, currentYear, selectedMonth, selectedYear])

    const onClickHandler = (el) => {
        let url = "";
        if(el?.document_file?.[0]){
            url = el?.document_file?.[0];
        }else if(el?.key_coverages_link?.link){
            url = el?.key_coverages_link?.link;
        }
        window.open(url, "_blank");
    }

    return (
        <div className="cards">
            <div className="bg-primary">
                <div className="container">
                    <div className="row head_row">
                        <div className="col-md-8">
                            <div className="row align-items-center">
                                <div className="col-md-4">
                                    <div className="form-group select-dd select-dd-mm-yy mb-0">
                                        <label for="archieve_year">Year</label>
                                        <select onChange={handleYearChange} value={selectedYear} name="" id="archieve_year" className="form-control custom-select" placeholder={selectedYear ? selectedYear : 'Select Year'}>
                                            {yearsList?.length
                                            ? yearsList.map(year => {
                                                return (
                                                    <option key={year} value={year}>{year}</option>
                                                )
                                            })
                                            : null}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group select-dd select-dd-mm-yy mb-0">
                                        <label for="archieve_month">Month</label>
                                        <select onChange={handleMonthChange} value={selectedMonth} name="" id="archieve_month" className="form-control custom-select" placeholder={selectedMonth ? monthsList.find(el => el?.id == selectedMonth)?.label : 'Select Month'}>
                                            {monthsList?.length
                                            ? monthsList.map(month => {
                                                return (
                                                    <option key={month?.id} value={month?.id}>{month?.label}</option>
                                                )
                                            })
                                            : null}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <a href="javascript:;" className="btn btn-primary btn-white-bg" onClick={getCustomData}>Go</a>
                                </div>
                            </div>      
                        </div>
                        <div className="col-md-4">
                            <div className="search_box_services">
                                <div className="input-group">
                                    <DebounceInput
                                        minLength={2}
                                        debounceTimeout={300}
                                        onChange={onSearchTextChange} 
                                        value={searchText}
                                        placeholder="Search"
                                        className="form-control"
                                    />
                                    <div className="input-group-append">
                                        <button className="btn btn-search" type="button"> <img src="/assets/images/icons/Icon-feather-search.svg" alt="" /> </button>
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>
                    <div className="d-flex mt-3">
                        <a className="ml-auto text-white cursor-pointer" onClick={resetData}>Clear all</a>
                    </div>
                </div>
            </div>
            <h2 className="section-heading pt-3">Key Coverages</h2>
            <div className="table-price-wrap table-with-blue-border pb-3">
                <table border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tbody>
                        <tr>
                          <th><strong>Publication</strong></th>
                            <th><strong>Title</strong></th>
                            <th><strong>Date</strong></th>
                        </tr>
                        {list?.length && !loading
                        ? list.map((el,i) => {
                            return (
                                <tr key={i}>
                                    <td>{el?.field_publication_name}</td>
                                    <td><a onClick={() => onClickHandler(el)} href="javascript:void(0);" className="text-decoration-underline">{el?.title}</a></td>
                                    <td>{el?.formatted_date}</td>
                                </tr>
                            )
                        })
                        : null}
                        {!list?.length && !loading ? <p className="p-2">No Data Found.</p> : null}
                    </tbody>
                </table>
                {loading && <Loader />}
            </div> 
        </div>
    )
}

export default KeyCoverage