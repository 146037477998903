import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout/layout"
import Meta from "../../components/addon/meta"
import Schema from "../../components/addon/schema"
import PressReleaseTabPanel from "../../components/addon/tabpanel/pressRelease"

import useScript from '../../service/useScript'

import API from "../../api/events"
import KeyCoverage from "../../components/addon/tabpanel/key-coverage"
import Keynews from "../../components/addon/tabpanel/key-news"

const PressRelease = ({ data: { allNodeTemplatePages: { edges } } }) => {
    const rawData = edges[0]
    const metaTags = rawData.node?.metatag_normalized.length > 0 ? rawData?.node?.metatag_normalized : [];
    const schema = rawData?.node?.relationships?.field_seo_schema ? rawData?.node?.relationships?.field_seo_schema : []
    const canonicalUrl = rawData.node?.metatags_on_page || {}
    const data = rawData?.node?.relationships?.components;
    const defaultLimit = 5;
   
    let [loading, setLoading] = useState(false)
    let [displayData, setDisplayData] = useState([])
    let [list, setList] = useState([])

    const [pressMediaContent, setPressMediaContent] = useState([])
    const [pressVideo, setPressVideo] = useState([])
    const [selectedVideo, setSelectedVideo] = useState(null)
    const [bannerData] = data
    
    const bannerImg = typeof (bannerData?.relationships?.components[0]?.relationships?.bannerImage?.uri?.url) !== "undefined" ? bannerData?.relationships?.components[0]?.relationships?.bannerImage?.uri?.url : "/assets/images/patient_care/banner_patient_care.jpg"
    const bannerAlt = typeof (bannerData?.relationships?.components[0]?.bannerImage?.alt) !== "undefined" && bannerData?.relationships?.components[0]?.bannerImage?.alt?.length > 0 ? bannerData?.relationships?.components[0]?.bannerImage?.alt : "Hinduja Hospital"
    const bannerTitle = typeof (bannerData?.relationships?.components[0]?.title) !== "undefined" && bannerData?.relationships?.components[0]?.title?.length > 0 ? bannerData?.relationships?.components[0]?.title : "";

    const setVideo = (videoUrl) => {
        if(videoUrl) {
            let data_src = videoUrl.split('/')
            if(data_src[data_src?.length-1]) {
                setSelectedVideo(`https://www.youtube.com/embed/${data_src[data_src?.length-1]}`)
            }
        }
    }

    const getMediaContentData = () => {
        API.getPressReleasesMediaContent()
        .then(res => {
            if(res?.data) {
                setPressMediaContent(res.data)
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    const getPressVideoData = () => {
        API.getPressReleasesVideo()
        .then(res => {
            if(res?.data) { 
                // let arr = res.data.filter(el => el?.video_url !== null)
                setPressVideo(res.data)
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    const loadMoreData = () => {
        const counter = displayData.length + defaultLimit
        const arr = [...list.slice(displayData.length, counter)]
        setDisplayData([...displayData, ...arr])
    }

    useEffect(() => {
        getPressVideoData()
        getMediaContentData()
    }, [])

    useEffect(() => {
        if(list.length === 0){
            setDisplayData([])
        }else if(list.length <= defaultLimit)
            setDisplayData([...list])
        else{
            loadMoreData()
        }
    }, [list])

    useScript("/assets/js/custom_select.js"); 

    useEffect(() => {
        if (typeof window.initSelect !== 'undefined') window.initSelect();
    }, [list])

    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: [
                            "/assets/css/common.css", 
                            "/assets/css/blog.css", 
                            "/assets/css/accessibility.css",
                            "/assets/css/testimonial.css",
                            "/assets/css/press-release.css",
                        ],
                    }}
                    tags={{metaTags,canonicalUrl}}
                />
                <Schema schema={schema} />
                <main className="innerpage">
                    <section className="inner_common_banner">
                        <picture className="banner_img">
                          <img src={bannerImg} alt={bannerAlt} />
                        </picture>
                        <div className="banner_content text_left text_dark">
                          <div className="container">
                            <h1>{bannerTitle}</h1>
                          </div>
                        </div>
                    </section>
                     
                    <section class="section-pb bg-white archive_section pressReleasewrap">
                        <div class="container">
                            <div class="aboutContainer">
                                <ul class="nav nav-tabs gallery-tabs min-space" role="tablist">
                                    <li role="presentation" class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#pressRelease" role="tab" aria-controls="pressRelease" aria-selected="false">Press Release</a> </li>
                                    <li role="presentation" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#keyCoverage" role="tab" aria-controls="keyCoverage" aria-selected="true">Key Coverages</a> </li>
                                    <li role="presentation" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#keyNews" role="tab" aria-controls="keyNews" aria-selected="false">Key News</a> </li>
                                    <li role="presentation" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#videos" role="tab" aria-controls="videos" aria-selected="false">Videos</a> </li>
                                    <li role="presentation" class="nav-item"> <a class="nav-link" data-toggle="tab" href="#mediaContact" role="tab" aria-controls="mediaContact" aria-selected="false">Media Contact</a> </li>
                                </ul>
                            </div>
                            <div class="tab-content mt-3">
                                <div class="tab-pane active" id="pressRelease" role="tabpanel" aria-labelledby="pressRelease">
                                    <PressReleaseTabPanel />
                                </div>
                                <div class="tab-pane" id="keyCoverage" role="tabpanel" aria-labelledby="keyCoverage">
                                    <KeyCoverage />
                                </div>
                                <div class="tab-pane" id="keyNews" role="tabpanel" aria-labelledby="keyNews">
                                    <Keynews />
                                </div>
                                <div class="tab-pane" id="videos" role="tabpanel" aria-labelledby="videos">
                                    <div class="cards">
                                        <h2 class="section-heading pt-3">Videos</h2>
                                        <div className="videoWrap testimonialWrap">
                                            <div className="row">
                                                {pressVideo?.length 
                                                    ? pressVideo.map((video, index) => {
                                                        return (
                                                            <div className="col-md-4" key={index}>
                                                                <div className="videoBox">
                                                                    <a href="javascript:;" onClick={() => setVideo(video?.youtube_video)} className="video-popup" data-toggle="modal" data-target="#modal_video" data-src="q_mU0XUmlfY">
                                                                        <img src={video?.video_thumbnail} alt={video?.title} />
                                                                        <span className="playIcon"><img src="/assets/images/testimonial/video-play-icon.png" alt="Video Play Icon" /></span>
                                                                    </a>
                                                                    <h4>{video?.title}</h4>
                                                                </div>  
                                                            </div>
                                                        )
                                                    })
                                                    : <div className="col-12">No Videos Found.</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                <div class="tab-pane" id="mediaContact" role="tabpanel" aria-labelledby="mediaContact">
                                    <div class="cards">
                                        <h2 class="section-heading pt-3">Media Contact</h2>
                                        {pressMediaContent?.length 
                                        ? pressMediaContent.map((content,i) => {
                                            return (
                                                <p key={i}>
                                                    <strong>{content?.title }</strong> :<br /> 
                                                    <span>Tel</span> : <a class="text-decoration-underline" href={`tel:${content?.contact_no}`}>{content?.contact_no || 'NA'}</a><br />
                                                    <span>Email ID</span> : <a class="text-decoration-underline" href={`mailto:${content?.email_id}`}>{content?.email_id || 'NA'}</a>    
                                                </p>  
                                            )
                                        }) 
                                        : 'No Media Contacts Found'}
                                    </div>
                                </div>  
                            </div>
                        </div>   
                    </section>
                </main>
                <div className="modal fade modal_video modal-center" id="modal_video" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button onClick={() => setSelectedVideo(null)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">X</span>
                                </button>
                                <iframe width="100%" height="315" src={selectedVideo ? selectedVideo : ''} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export const query = graphql`
query pressReleaseListing {
    allNodeTemplatePages(
        filter: {path: {alias: {regex: "/press-release$/"}}}
    ) {
    edges {
    node {
    id
    title
    path {
    alias
    }
    relationships{
        field_seo_schema{
            field_seo_title
            field_seo_schema_text_area
        }
    }
    metatag_normalized {
    attributes {
    content
    name
    property
    }
    }
    metatags_on_page: field_meta_tags{
        canonical_url
      }
    relationships {
    components: field_component_type {
    __typename
    ...ParagraphSectionPRL
    }
    }
    }
    }
    }
    }
    
    
    
    fragment ParagraphSectionPRL on paragraph__section {
    id
    name: field_name
    relationships {
    components: field_components {
    __typename
    ...ParagraphBannerPRL
    }
    }
    }
    
    
    
    fragment ParagraphBannerPRL on paragraph__banner {
    id
    title: field_title
    subtitle: field_subtitle
    bannerImage: field_banner_image {
    alt
    }
    bannerLink: field_banner_link {
    uri
    title
    }
    relationships {
    bannerImage: field_banner_image {
    id
    uri {
        value
        url
    }
    }
    }
    }
`
export default PressRelease